import { Link } from "gatsby"
import React from "react"
import Layout from '../components/layout';
import { graphql } from 'gatsby';

const AllStoresPage = ({ data }) => {
	const seoData = {
		'title' : 'All stores page', 
		'description' : 'All stores page', 
		'canonicalUrl' : '/all-stores/', 
		'robots' : 'index,follow'
		};
	
	return(
  <Layout page={seoData} >
    <section className="container all-list-wrapper">
      <h1>All stores</h1>
      <div>
        {data.hc.stores.map(store => (
          <h2 key={store.id}> <Link to={store.page.url}>{store.name}</Link></h2>
        ))}
      </div>
    </section>
  </Layout>
)
}

export default AllStoresPage

export const allStores = graphql`
  query {
    hc {
      stores (where : {OR : [{ disabled : false}, {disabled : null}]}, orderBy: name_ASC) {
        id
        name
		page {url gatsbyUrl}
       }
    }
  }
`;
